import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { TokenStateService } from '@pixels/client/interceptors/token-state.service';
import { isEmpty, uniqueId } from 'lodash-es';
import { defer, Observable, switchMap } from 'rxjs';

export function tokenInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const tokenStateService = inject(TokenStateService);
  return defer(() => {
    const key = uniqueId('token');
    console.time(key);
    return tokenStateService.currentToken.pipe(
      switchMap(token => {
        console.log('tokenInterceptor', new URL(req.url).pathname, token?.slice(-8));
        if (!isEmpty(token)) {
          req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        }
        console.timeEnd(key);
        return next(req);
      })
    );
  });
}
