import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { isEmptyForInstaChatUserByOneOnOneType } from '@pixels/client/one-on-one/core/constant';
import { InstaChatUserApiService } from '@pixels/client/one-on-one/core/services/insta-chat-user-api.service';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { isEmpty } from 'lodash-es';
import { map, Observable, switchMap, timer } from 'rxjs';

export function alreadyInstaChatUserProfileGuard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const instaChatUserApiService = inject(InstaChatUserApiService);
  const navCtrl = inject(NavController);
  const appType = inject(AppInfo).appType;

  return timer(100).pipe(
    switchMap(() => instaChatUserApiService.fetchMe()),
    map(user => {
      if (isEmpty(user.gender) || isEmpty(user.user.username) || isEmptyForInstaChatUserByOneOnOneType(user, appType)) {
        return true;
      }
      setTimeout(() => navCtrl.navigateRoot('/', {
        queryParams: { returnUrl: state.url },
        replaceUrl: true
      }), 100);
      return false;
    })
  );
}

