import { HttpStatusCode } from '@angular/common/http';
import { computed, effect, inject, Injectable, NgZone, signal } from '@angular/core';
import { getAuth } from '@angular/fire/auth';
import { NavigationEnd, Router } from '@angular/router';
import { Importance, Visibility } from '@capacitor-firebase/messaging';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Badge } from '@capawesome/capacitor-badge';
import { IonRouterOutlet, NavController, Platform } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { AdmobService } from '@pixels/client/admob/admob.service';
import { InstaChatProperty } from '@pixels/client/anayltics/analytics.model';
import { AnalyticsService } from '@pixels/client/anayltics/analytics.service';
import { IonicNativeAuthService } from '@pixels/client/capacitor/ionic-native-auth.service';
import { ChatAuthService } from '@pixels/client/chat-auth/chat-auth.service';
import { NationSelectModalService } from '@pixels/client/components/nation-select/nation-select-modal.service';
import { PhotoViewerStateService } from '@pixels/client/components/photo-viewer/photo-viewer-state.service';
import { WINDOW } from '@pixels/client/dom/window-token';
import { GlobalSpinnerV2Service } from '@pixels/client/global-spinner/global-spinner-v2.service';
import { IdleManagerService } from '@pixels/client/idle/idle-manager.service';
import { VAPID_KEY_TOKEN } from '@pixels/client/injection-tokens/environment-token';
import { IonicApp } from '@pixels/client/ionic/ionic-app';
import { confirmDialog, globalCatchError, toastDialog } from '@pixels/client/ionic/ionic-helper';
import { HistoryService } from '@pixels/client/navigations/history.service';
import { AppStateService } from '@pixels/client/ngforage/app-state.service';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { IonicPushService } from '@pixels/client/push/ionic-push.service';
import { TalkPlusService } from '@pixels/client/talkplus/talk-plus.service';
import { ApplyStatus } from '@pixels/universal/model/insta-chat/apply-chat/apply-chat.model';
import { ChattingType } from '@pixels/universal/model/insta-chat/insta-chat-room/insta-chat-room.model';
import { getNationItemsByCountryCode } from '@pixels/universal/model/nation/nation.model';
import { DeviceType, PIXEL_NOTIFICATION_CHANNEL, PushType } from '@pixels/universal/model/push-token/push.model';
import {
  ChatRoomDataKey,
  EventTypeEnum,
  TalkPlusEventResponse,
  TalkPlusEventResponseForChannelChanged,
  TalkPlusEventResponseForMessage
} from '@pixels/universal/model/talk-plus/talk-plus.model';
import { captureException, setTag } from '@sentry/angular';
import { type CountryCode } from 'libphonenumber-js';
import { isEqual } from 'lodash-es';
import {
  catchError,
  concatMap,
  defer,
  delay,
  delayWhen,
  EMPTY,
  exhaustMap,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  timer
} from 'rxjs';
import { isAdminPath, PATH_MAP } from './core/constant';
import { GlobalStateService } from './core/services/global-state.service';
import { InstaChatCountState } from './core/services/insta-chat-count.state';
import { InstaChatUserApiService } from './core/services/insta-chat-user-api.service';

@Injectable()
export class AppService {
  public readonly currentVersion = signal<string | undefined>(undefined);
  private readonly connectTalkPlusSource = new Subject<void>();
  private readonly stopTalkPlusSource = new Subject<void>();
  private readonly router = inject(Router);
  private readonly historyService = inject(HistoryService);
  private readonly globalStateService = inject(GlobalStateService);
  private readonly photoViewerStateService = inject(PhotoViewerStateService);
  public readonly isOpenPhotoViewer = this.photoViewerStateService.isOpenPhotoViewer.asReadonly();
  public readonly photoViewerParam = this.photoViewerStateService.photoViewerParam.asReadonly();
  private readonly talkPlusService = inject(TalkPlusService);
  private readonly idleManager = inject(IdleManagerService);
  private readonly pushService = inject(IonicPushService);
  private readonly platform = inject(Platform);
  private readonly chatAuthService = inject(ChatAuthService);
  public readonly currentPhoneNumber = computed(() => this.chatAuthService.currentChatUser()?.phoneNumber);
  public readonly loggedIn = computed(() => !!this.chatAuthService.currentChatUser());
  private readonly nav = inject(NavController);
  private readonly instaChatCountState = inject(InstaChatCountState);
  private readonly instaChatUserApiService = inject(InstaChatUserApiService);
  private readonly globalSpinnerService = inject(GlobalSpinnerV2Service);
  private readonly vapidKey = inject(VAPID_KEY_TOKEN);
  private readonly ionicApp = inject(IonicApp);
  private readonly isNative = this.platform.is('hybrid');
  private readonly isIos = this.platform.is('ios');
  private readonly isAndroid = this.platform.is('android');
  private readonly analytics = inject(AnalyticsService);
  private readonly ngZone = inject(NgZone);
  private readonly readyToLoadSource = new ReplaySubject<void>(1);
  private readonly win = inject(WINDOW);
  public readonly currentHost = this.win.location.host;
  private readonly logoutSource = new Subject<void>();
  private readonly appState = inject(AppStateService);
  private readonly enableNationNumber = inject(AppInfo).enableNationNumber;
  private readonly nationSelectModalService = inject(NationSelectModalService);
  private readonly t = inject(TranslateService);
  private readonly checkSelectNationSource = new Subject<void>();
  private readonly homeFullPath = inject(AppInfo).homeFullPath;
  private readonly ionicNativeAuthService = inject(IonicNativeAuthService);
  private readonly admobService = inject(AdmobService);
  private readonly username = computed(() => this.chatAuthService.currentChatUser()?.username);
  private routerOutlet!: IonRouterOutlet;

  constructor() {
    effect(() => this.effectCounts());
  }

  private get initialApp(): Observable<any> {
    return defer(() => {
      if (this.isNative) {
        this.platform.backButton.subscribeWithPriority(-1, () => {
          if (!this.routerOutlet.canGoBack()) {
            if ([
              this.homeFullPath,
              PATH_MAP.login.fullPath,
              PATH_MAP.punishment.fullPath
            ].includes(this.win.location.pathname)) {
              App.exitApp();
            } else {
              this.nav.navigateRoot(this.homeFullPath, { replaceUrl: true });
            }
          }
        });
      }
      return of(undefined);
    }).pipe(
      switchMap(() => this.ionicApp.getInfo()),
      tap(info => {
        if (info) {
          setTag('build', info.build);
          setTag('version', info.version);
          this.currentVersion.set(`${info.version} ${info.build}`);
        }
      })
    );
  }

  private get attachLanguageChange$(): Observable<any> {
    return timer(100).pipe(
      switchMap(() => this.t.onLangChange),
      switchMap(() => {
        const languageCode = this.t.currentLang;
        getAuth().languageCode = languageCode;
        if (this.isNative) {
          return this.ionicNativeAuthService.setLanguageCode({ languageCode });
        } else {
          return of(undefined);
        }
      }),
      switchMap(() => this.appState.getItem('savedDeviceTokenParam').pipe(
        switchMap(savedDeviceTokenParam => {
          const language = this.t.currentLang;
          if (savedDeviceTokenParam && savedDeviceTokenParam.language !== language) {
            return this.talkPlusService.updateRegisterToken({ token: savedDeviceTokenParam.token, language }).pipe(
              switchMap(() => this.appState.setItem('savedDeviceTokenParam', { ...savedDeviceTokenParam, language })),
              catchError(e => of(undefined))
            );
          }
          return of(undefined);
        })
      ))
    );
  }

  private get attachUpdateConnectedAt$(): Observable<any> {
    return this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      take(1),
      delay(100),
      tap(() => this.readyToLoadSource.next())
    );
  }

  private get attachAnalytics$(): Observable<any> {
    return this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      concatMap(({ url }) => {
        if (isAdminPath(url)) {
          return of(undefined);
        } else {
          return this.analytics.setCurrentScreen({ screenName: url });
        }
      }),
      globalCatchError()
    );
  }

  private get hideSplash$(): Observable<any> {
    return this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      take(1),
      switchMap(() => SplashScreen.hide())
    );
  }

  private get startConnect(): Observable<number> {
    return timer(10).pipe(
      tap(() => this.connectTalkPlusSource.next())
    );
  }

  private get attachIdle$(): Observable<any> {
    return forkJoin([
      this.idleManager.idleStart$.pipe(tap(() => this.stopTalkPlusSource.next())),
      this.idleManager.idleEnd$.pipe(tap(() => this.connectTalkPlusSource.next())),
    ]);
  }

  private get registerPushToken(): Observable<boolean> {
    return defer(() => {
      // Notification is not available in this browser.
      if (!this.isNative && typeof Notification === 'undefined') {
        return of(false);
      }

      return this.appState.getItem('savedDeviceTokenParam').pipe(
        switchMap(savedDeviceTokenParam => {
          const userId = this.chatAuthService.currentChatUser()?.id;
          if (!userId) {
            return of(false);
          }

          return this.pushService.register(this.vapidKey).pipe(
            switchMap(({ token }) => defer(() => {
              if (!token) {
                return of(false);
              }
              const language = this.t.currentLang;
              return this.pushService.checkPermissionLocalNotification().pipe(
                switchMap(() => {
                  const { protocol, host } = this.win.location;
                  const url = `${protocol}//${host}`;
                  if (isEqual(savedDeviceTokenParam, { token, userId, language, url })) {
                    return of(true);
                  }
                  const savedToken = savedDeviceTokenParam?.token;
                  const deviceType = this.getDeviceType();
                  return defer(() => {
                    const prevAction = (savedToken && savedToken !== token) ? this.talkPlusService.unregisterToken({ token: savedToken }).pipe(catchError(e => of(undefined))) : of(undefined);
                    return prevAction.pipe(
                      switchMap(() => this.talkPlusService.registerToken({ token, deviceType, url, language }).pipe(
                        switchMap(() => this.appState.setItem('savedDeviceTokenParam', { token, userId, language, url })),
                        map(() => true)
                      ))
                    );
                  });
                }),
              );
            })),
          );
        }),
        catchError(err => {
          captureException(err, { tags: { from: 'registerPushToken' } });
          console.error('registerPushToken', err);
          // return unregisterServiceWorkers();
          return of(false);
        })
      );
    });
  }

  private get attachPushNotificationReceived$(): Observable<any> {
    return this.pushService.pushNotificationReceived$.pipe(
      catchError(err => {
        console.error('pushNotificationReceived$', err);
        captureException(err, { tags: { from: 'pushNotificationReceived$' } });
        return of(undefined);
      })
    );
  }

  private get attachPushNotificationActionPerformed$(): Observable<any> {
    return this.pushService.pushNotificationActionPerformed$.pipe(
      delayWhen(() => this.readyToLoadSource),
      delay(200),
      tap(v => {
        console.log('pushNotificationActionPerformed$', v);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { type, targetPath } = v.notification?.data ?? {};

        switch (type as PushType) {
          case PushType.receivedChat:
          case PushType.receivedMessage:
          case PushType.recommendFriend:
            break;
        }
        this.ngZone.run(() => this.nav.navigateForward(targetPath));
      }),
      delay(200),
      catchError(err => {
        console.error(err);
        captureException(err, { tags: { from: 'pushNotificationActionPerformed$' } });
        return of(undefined);
      })
    );
  }

  private get attachLocalPushNotificationActionPerformed$(): Observable<any> {
    return this.pushService.localPushNotificationActionPerformed$.pipe(
      delayWhen(() => this.readyToLoadSource),
      delay(200),
      tap(v => {
        console.log('localPushNotificationActionPerformed$', v);
        const { type, targetPath } = v.notification?.extra ?? {};

        switch (type as PushType) {
          case PushType.receivedChat:
          case PushType.receivedMessage:
          case PushType.recommendFriend:
            break;
        }
        this.ngZone.run(() => this.nav.navigateForward(targetPath));
      }),
      delay(200),
      catchError(err => {
        captureException(err, { tags: { from: 'localPushNotificationActionPerformed$' } });
        console.error(err);
        return of(undefined);
      })
    );
  }

  private get attachTalkPlusEvent$(): Observable<TalkPlusEventResponse> {
    return this.connectTalkPlusSource.pipe(
      switchMap(() => this.chatAuthService.init()),
      filter(user => !!user),
      switchMap(user => user ? user.getIdTokenResult() : EMPTY),
      filter(idTokenResult => !!idTokenResult.claims.id),
      switchMap(() => this.chatAuthService.loginTalkPlus()),
      switchMap(() => this.talkPlusService.event$.pipe(
        takeUntil(this.stopTalkPlusSource),
        tap(data => {
          switch (data.type) {
            case EventTypeEnum.ChannelChanged:
              this.checkChannelChanged(data);
              break;
            case EventTypeEnum.ChannelRemoved:
              this.globalStateService.emitReloadChats(ChattingType.conversation);
              this.globalStateService.emitReloadChats(ChattingType.applied);
              this.globalStateService.emitReloadChats(ChattingType.received);
              this.globalStateService.emitReloadChats(ChattingType.open);
              this.instaChatCountState.triggerFetchApplyCounts();
              this.instaChatCountState.triggerFetchTotalUnreadCount();
              break;
          }
        }),
        catchError(err => {
          captureException(err, { tags: { from: 'attachTalkPlusEvent$' } });
          return timer(500).pipe(tap(() => this.win.location.reload()));
        })
      )),
      globalCatchError()
    );
  }

  private get attachLoggedInByReadyMode$(): Observable<boolean> {
    return this.chatAuthService.currentChatUser$.pipe(
      // 이름 설정까지 된 상태부터 로그인으로 간주
      map(chatUser => !!chatUser?.username),
    );
  }

  private get appResume$(): Observable<any> {
    return defer(() => {
      if (this.isNative) {
        return this.ionicApp.resume$.pipe(
          filter(() => this.loggedIn() && !!this.username()),
          switchMap(() => forkJoin([
            this.chatAuthService.updateToken(),
            this.instaChatUserApiService.fetchMe(),
          ]).pipe(
            catchError(err => {
              if ([HttpStatusCode.NotFound, HttpStatusCode.Unauthorized].includes(err.status)) {
                return this.chatAuthService.logout();
              }
              console.error(err);
              return of(undefined);
            }),
          )),
          tap(() => this.checkSelectNationSource.next()),
          globalCatchError()
        );
      }
      return of(undefined);
    });
  }

  private get logout$(): Observable<any> {
    return this.logoutSource.pipe(
      exhaustMap(() => confirmDialog({
        message: '로그아웃 하시겠습니까?',
        okText: '로그아웃',
        cancelText: '취소'
      })),
      switchMap(proceed => proceed ? this.chatAuthService.logout() : of(undefined)),
      globalCatchError()
    );
  }

  private get checkSelectNation$(): Observable<any> {
    return this.checkSelectNationSource.pipe(
      exhaustMap(() => {
        if (!this.enableNationNumber) {
          return of(undefined);
        }
        return timer(1000).pipe(
          filter(() => this.loggedIn()),
          switchMap(() => this.instaChatUserApiService.fetchMe().pipe(catchError(e => of(undefined)))),
          exhaustMap(user => {
            if (!user) {
              return of(undefined);
            }
            if (user.countryCode || !user.gender || !user.birthday) {
              return of(undefined);
            }
            return this.nationSelectModalService.open(getNationItemsByCountryCode()).pipe(
              exhaustMap(({ role, data }) => {
                if (role !== 'select' || !data?.country) {
                  return of(undefined);
                }
                return this.instaChatUserApiService.updateMe({ countryCode: data.country as CountryCode }).pipe(
                  tap(() => this.analytics.setUserProperty({ key: InstaChatProperty.countryCode, value: user.countryCode as string })),
                  switchMap(() => toastDialog(this.t.instant('@국가가_설정되었습니다@'))),
                );
              }),
            );
          })
        );
      }),
      globalCatchError()
    );
  }

  private get createChannel(): Observable<void> {
    return defer(() => {
      if (this.isNative && this.isAndroid) {
        return this.pushService.createChannel({
          id: PIXEL_NOTIFICATION_CHANNEL,
          name: PIXEL_NOTIFICATION_CHANNEL,
          // @ts-ignore android 모듈의 필드 이름 오류
          vibrate: true,
          vibration: true,
          visibility: Visibility.Public,
          importance: Importance.High,
          description: 'PIXEL notification channel'
        });
      }
      return of(undefined);
    });
  }

  public async admobInitialize(): Promise<void> {
    await this.admobService.initialize();
  }

  public init$(routerOutlet: IonRouterOutlet): Observable<any> {
    this.routerOutlet = routerOutlet;
    this.initStatus();
    return forkJoin([
      timer(100).pipe(switchMap(() => this.admobInitialize())),
      this.globalSpinnerService.hidePreloader$,
      this.initialApp,
      // this.checkUpdateAndReloadAction$,
      this.historyService.init$,
      this.attachUpdateConnectedAt$,
      this.attachLanguageChange$,
      this.attachAnalytics$,
      this.hideSplash$,
      this.photoViewerStateService.init$,
      this.appResume$,
      this.checkSelectNation$,
      this.logout$,
      timer(1000).pipe(tap(() => this.checkSelectNationSource.next())),
      this.attachLoggedInByReadyMode$.pipe(
        switchMap(loggedIn => {
          if (!loggedIn) {
            return of(undefined);
          }
          return forkJoin([
            this.attachTalkPlusEvent$,
            this.startConnect,
            this.attachIdle$,
            this.instaChatCountState.init$,
            this.triggerInitialize(),
            this.registerPushToken.pipe(
              switchMap(enabled => enabled ? forkJoin([
                this.attachPushNotificationReceived$,
                this.attachLocalPushNotificationActionPerformed$,
                this.attachPushNotificationActionPerformed$,
                this.createChannel
              ]) : of(undefined))
            ),
          ]);
        })
      ),
    ]);
  }

  public initStatus(): void {
    // Display content under transparent status bar (Android only)
    // StatusBar.setOverlaysWebView({ overlay: true });
    // StatusBar.hide();
  }

  public closePhotoViewer(): void {
    this.photoViewerStateService.back();
  }

  public logout(): void {
    this.logoutSource.next();
  }

  public changeCurrentUrl(url: string): void {
    this.photoViewerStateService.changeCurrentUrl(url);
  }

  public didDimissPhotoViewer(): void {
    this.photoViewerStateService.didDismiss();
  }

  private effectCounts(): void {
    if (!this.username()) {
      return;
    }
    const unreadChatMessageTotalCount = this.instaChatCountState.unreadChatMessageTotalCount();
    const receivedTotalCount = this.instaChatCountState.receivedCount();
    if (Capacitor.isNativePlatform()) {
      Badge.set({ count: unreadChatMessageTotalCount + receivedTotalCount });
    }
  }

  private checkChannelChanged(data: TalkPlusEventResponseForChannelChanged | TalkPlusEventResponseForMessage): void {
    switch (data.channel.data?.[ChatRoomDataKey.applyStatus]) {
      case ApplyStatus.applied:
        this.globalStateService.emitReloadChats(ChattingType.received);
        this.globalStateService.emitReloadChats(ChattingType.applied);
        this.instaChatCountState.triggerFetchApplyCounts();
        this.instaChatCountState.triggerFetchTotalUnreadCount();
        return;
      case ApplyStatus.connected:
        this.globalStateService.emitReloadChats(ChattingType.applied);
        this.globalStateService.emitReloadChats(ChattingType.received);
        this.globalStateService.emitReloadChats(ChattingType.conversation);
        this.instaChatCountState.triggerFetchTotalUnreadCount();
        return;
      default:
        this.globalStateService.emitReloadChats(ChattingType.conversation);
        this.globalStateService.emitReloadChats(ChattingType.open);
        this.instaChatCountState.triggerFetchTotalUnreadCount();
    }
  }

  private getDeviceType(): DeviceType.ios | DeviceType.web | DeviceType.android {
    if (this.isNative) {
      if (this.isIos) {
        return DeviceType.ios;
      } else if (this.isAndroid) {
        return DeviceType.android;
      }
    }
    return DeviceType.web;
  }

  private triggerInitialize(): Observable<any> {
    return defer(() => {
      this.instaChatCountState.triggerFetchApplyCounts();
      this.instaChatCountState.triggerFetchTotalUnreadCount();
      return of(undefined);
    });
  }
}
